import Layout from 'components/layout';
import { initCMS } from 'utils/cms';

import { useI18n } from 'context/i18n';
import { getDomainValue } from 'utils/domain';
import { Link } from 'components/ui/link';

export default function NotFoundPage({ locale, defaultProps }) {
  const { t } = useI18n();

  return (
    <Layout
      {...defaultProps}
      metatags={{ pageTitle: '404', metaDescription: '404' }}
      breadcrumbs={getDomainValue({
        camper: true,
        nnormal: true,
        camperlab: false,
      })}
    >
      <div>
        <main className="flex flex-col items-center justify-center px-2.5 pb-40 pt-32 [@media(min-width:1440px)]:pb-64">
          <h1 className="my-8 px-8 text-center text-2xl">{t('index', 'info.title.404', "Sorry, but it looks like this page doesn't exist.")}</h1>
          <Link variant="solid" href={`/${locale}`}>
            {t('index', 'info.btn.404', 'Go back to the Homepage')}
          </Link>
        </main>
      </div>
    </Layout>
  );
}

NotFoundPage.i18nPage = 'NotFoundPage';

export async function getStaticProps(context) {
  console.log(`Generando /404: ${context.locale}`);

  const locale = ['default', 'int'].includes(context.locale) ? 'es_ES' : context.locale;

  let props = {
    locale: context.locale,
  };

  try {
    const { defaultProps } = await initCMS({ context, locale });
    props = {
      ...props,
      defaultProps,
    };
  } catch {
    console.log(`ERROR AL INSTANCIAR EL CMS PARA : ${context.locale}`);
  }

  return { props };
}
